/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */

// @mui material components

// react-routers components
import { useParams } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MaterialReactTable from "material-react-table";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

import { useAuth } from "layouts/authentication/AuthContext";

import { useMemo, useState, useEffect } from "react";
// firebase functions
import { doc, getDoc, getDocs, query, where, collection, updateDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import { db } from "../../../firebaseDb";

const totalChemical = (
  amount,
  measure,
  hectares,
  unit,
  waterRate,
  tankCapacity,
  actualChemical
) => {
  const tanksperHectare = waterRate / tankCapacity;

  if (actualChemical) {
    return {
      text: `${Math.round(100 * actualChemical) / 100} ${unit}`,
      number: Math.round(100 * actualChemical) / 100,
    };
  }
  if (measure === "Tank") {
    return {
      text: `${Math.round(100 * amount * tanksperHectare * hectares) / 100} ${unit}`,
      number: Math.round(100 * amount * tanksperHectare * hectares) / 100,
    };
  }
  if (measure === "Hectare") {
    return {
      text: `${Math.round(100 * Number(amount) * hectares) / 100} ${unit}`,
      number: Math.round(100 * Number(amount) * hectares) / 100,
    };
  }
  if (measure === "1000 L") {
    return {
      text: `${Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100} ${unit}`,
      number: Math.round((100 * Number(amount) * waterRate * hectares) / 1000) / 100,
    };
  }

  return null;
};

function InvoiceSprayList({ title, invoiceData, shadow, chemicals }) {
  const { user } = useAuth();
  const authToken = user.accessToken;
  const { invoiceID } = useParams();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const totalCost = invoiceData.sprays.reduce((accumulator, current) => {
    const sprayCost = current.sprays.reduce((sprayAccumulator, spray) => {
      if (spray.weedspray) {
        return sprayAccumulator + spray.chemicalCost;
      }
      const chemicalCost = spray.chemicals.reduce(
        (chemicalAccumulator, chem) =>
          chemicalAccumulator +
          Number(
            (
              totalChemical(
                chem.sprayRate,
                chem.measure,
                spray.hectares,
                chem.unit,
                spray.waterRate,
                spray.tankCapacity,
                chem.actualChemical
              ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost
            ).toFixed(2)
          ),
        0
      );
      return sprayAccumulator + chemicalCost;
    }, 0);
    return accumulator + sprayCost;
  }, 0);

  async function updateRows(flatrows) {
    const invoiceRef = doc(db, "Invoices", invoiceID);

    // Fetch the document once
    const invoiceSnap = await getDoc(invoiceRef);
    if (invoiceSnap.exists()) {
      // Get the sprays array
      const updatedSprays = invoiceSnap.data().sprays || [];

      // Modify the sprays array for each selected row
      flatrows.forEach((row) => {
        const orchardSpray = updatedSprays.find(
          (spray) => spray.siteName === row.getValue("orchard")
        );
        if (orchardSpray) {
          orchardSpray.senttoProlorus = true;
        }
      });

      // Update the document with the modified sprays array once
      await updateDoc(invoiceRef, { sprays: updatedSprays });

      // Update the UI state after the document is updated
      setRowSelection({});
      setLoading(false);
    } else {
      console.log("No such document!");
      setLoading(false);
    }
  }

  const encryptandsend = (payload, flatrows) => {
    setLoading(true);
    const myHeaders = new Headers({
      Authorization: `Bearer ${authToken}`,
      // Add any other headers as needed.
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow",
    };
    fetch(
      "https://australia-southeast1-south-orchard.cloudfunctions.net/ProlorusWebhook",
      requestOptions
    )
      .then((result) => {
        console.log(result);
        if (result.ok) {
          updateRows(flatrows);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        alert("error", error);
      });
  };
  async function handleSetUnbillable(flatrows) {
    setLoading(true);
    const invoiceRef = doc(db, "Invoices", invoiceID);

    // Fetch the document once
    const invoiceSnap = await getDoc(invoiceRef);
    if (invoiceSnap.exists()) {
      // Get the sprays array
      const updatedSprays = invoiceSnap.data().sprays || [];

      // Modify the sprays array for each selected row
      flatrows.forEach((row) => {
        // Find the orchard spray by siteName
        const orchardSpray = updatedSprays.find((site) => site.siteName === row.original.orchard);
        if (orchardSpray) {
          // Find the spray by key (date-specific)
          const dateSpray = orchardSpray.sprays.find((spray) => spray.key === row.original.key);
          if (dateSpray) {
            // Find the chemical by name and toggle its unBilled status
            const chemSpray = dateSpray.chemicals.find(
              (chem) => chem.chemicalName === row.original.chemicalName
            );
            if (chemSpray) {
              // Toggle the unBilled property
              chemSpray.unBilled = !chemSpray.unBilled; // If true, set to false; if undefined, set to true
              console.log("Billing status changed...");
            }
          }
        }
      });

      // Update the document with the modified sprays array once
      await updateDoc(invoiceRef, { sprays: updatedSprays });

      // Update the UI state after the document is updated
      setRowSelection({});
      setLoading(false);
    } else {
      console.log("No such document!");
      setLoading(false);
    }
  }

  const TimestamptoDate = (timestamp) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const { seconds } = timestamp;
    const { nanoseconds } = timestamp;

    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    const date = new Date(milliseconds).toLocaleDateString("en-NZ", options).replace(/\//g, "-");
    return date;
  };

  function filterObject(obj, properties) {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => properties.includes(key)));
  }

  // Function to fetch orchardGUID from Firebase
  const fetchOrchardGUID = async (orchardCode) => {
    const orchardsRef = collection(db, "OrchardCodes");
    const q = query(orchardsRef, where("siteCode", "==", orchardCode));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const orchardData = querySnapshot.docs[0].data();
      return orchardData.orchardGUID;
    }
    setLoading(false);
    throw new Error("No such document!");
  };

  const handleProlorusAuthorization = async (rowsSelected, flatrows) => {
    const parsedSelection = [];

    const fetchPromises = rowsSelected.rows.map(async (item) => {
      if (!item.original.unBilled) {
        const filteredProperties = [
          "key",
          "orchard",
          "orchardCode",
          "chemicalName",
          "billableCost",
          "date",
        ];

        const filteredItem = filterObject(item.original, filteredProperties);

        try {
          const orchardGUID = await fetchOrchardGUID(item.original.orchardCode);
          if (!orchardGUID) {
            throw new Error(`Missing GUID for orchardCode: ${item.original.orchardCode}`);
          } else {
            filteredItem.orchardGUID = orchardGUID;
            parsedSelection.push(filteredItem);
          }
        } catch (error) {
          alert(`Error fetching orchardGUID: ${error.message}`);
          console.log("Error fetching orchardGUID:", error);
          setLoading(false);
          throw error; // Re-throw the error to stop further processing
        }
      }
    });

    try {
      await Promise.all(fetchPromises);
      encryptandsend(parsedSelection, flatrows);
    } catch (error) {
      console.log("Processing stopped due to an error:", error.message);
    }
  };

  useEffect(() => {
    const temprows = [];

    // Sorting the array by siteName
    invoiceData.sprays.sort((a, b) => {
      const siteNameA = a.siteName.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const siteNameB = b.siteName.toUpperCase();

      if (siteNameA < siteNameB) {
        return -1; // a should come before b
      }
      if (siteNameA > siteNameB) {
        return 1; // a should come after b
      }
      return 0; // a and b are equal in terms of sorting
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < invoiceData.sprays.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < invoiceData.sprays[i].sprays?.length; j++) {
        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < invoiceData.sprays[i].sprays[j].chemicals?.length; k++) {
          const spray = invoiceData.sprays[i].sprays[j];
          const chem = invoiceData.sprays[i].sprays[j].chemicals[k];
          temprows.push({
            color: i % 2 === 0 ? "white" : "#d9f3fa",
            key: invoiceData.sprays[i].sprays[j].key,
            id: `${i}${j}${k}`,
            orchard: invoiceData.sprays[i].siteName,
            orchardCode: invoiceData.sprays[i].siteCode,
            orchardGUID: invoiceData.sprays[i].orchardGUID,
            senttoProlorus: invoiceData.sprays[i].senttoProlorus,
            hectares: spray.hectares,
            date: TimestamptoDate(invoiceData.sprays[i].sprays[j].sprayDate),
            chemicalName: invoiceData.sprays[i].sprays[j].chemicals[k].chemicalName,
            unBilled: invoiceData.sprays[i].sprays[j].chemicals[k].unBilled,
            totalused: totalChemical(
              chem.sprayRate,
              chem.measure,
              spray.hectares,
              chem.unit,
              spray.waterRate,
              spray.tankCapacity,
              chem.actualChemical
            ).text,
            cost: `$${chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost} per Unit`,
            chemicalcost: Number(
              (
                totalChemical(
                  chem.sprayRate,
                  chem.measure,
                  spray.hectares,
                  chem.unit,
                  spray.waterRate,
                  spray.tankCapacity,
                  chem.actualChemical
                ).number * chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost
              ).toFixed(2)
            ),
            tenPercent: Number(
              (
                totalChemical(
                  chem.sprayRate,
                  chem.measure,
                  spray.hectares,
                  chem.unit,
                  spray.waterRate,
                  spray.tankCapacity,
                  chem.actualChemical
                ).number *
                chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost *
                0.1
              ).toFixed(2)
            ),
            billableCost: chem.unBilled
              ? 0
              : Number(
                  (
                    totalChemical(
                      chem.sprayRate,
                      chem.measure,
                      spray.hectares,
                      chem.unit,
                      spray.waterRate,
                      spray.tankCapacity,
                      chem.actualChemical
                    ).number *
                    chemicals.chemicals.filter((c) => c.key === chem.key)[0].cost *
                    1.1
                  ).toFixed(2)
                ),
          });
        }
      }
    }
    setRows(temprows);
  }, [invoiceData]);
  const columns = useMemo(
    () => [
      {
        header: "Orchard",
        accessorKey: "orchard",
        Cell: ({ cell, row, table }) => {
          // Get all rows in the table
          const allRows = table.getRowModel().rows;

          // Find rows that belong to the same group (same orchard value)
          const groupRows = allRows.filter((r) => r.original.orchard === row.original.orchard);

          // Check if any rows in the group meet the criteria
          const sentToProlorusInGroup = groupRows.some((r) => r.original.senttoProlorus);
          const unBilledInGroup = groupRows.some((r) => r.original.unBilled);

          return (
            <>
              {cell.getValue()}
              {sentToProlorusInGroup && <CheckCircleIcon color="success" fontSize="medium" />}
              {unBilledInGroup && <ErrorOutline color="error" fontSize="medium" />}
            </>
          );
        },
      },
      {
        accessorKey: "hectares",
        header: "Ha",
      },
      {
        accessorKey: "date",
        header: "Spray Date",
      },
      {
        accessorKey: "chemicalName",
        header: "Chemical",
      },
      {
        accessorKey: "unBilled",
        header: "Not Billed",
        Cell: ({ row }) => (
          <Checkbox
            colorScheme="red"
            checked={row.original.unBilled}
            disabled
            onChange={() => handleSetUnbillable(row)}
          />
        ),
      },
      {
        accessorKey: "totalused",
        header: "Total Used",
      },
      {
        accessorKey: "cost",
        header: "Cost",
      },
      {
        accessorKey: "chemicalcost",
        id: "chemcost",
        header: "Chem. Cost",
        Cell: ({ cell }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{`$${cell.getValue().toFixed(2)}`}</>
        ),
      },
      {
        accessorKey: "tenPercent",
        id: "tenpercent",
        header: "10%",
        Cell: ({ cell }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{`$${cell.getValue().toFixed(2)}`}</>
        ),
      },
      {
        accessorKey: "billableCost",
        header: "Billable Cost",
        aggregationFn: "sum",
        AggregatedCell: ({ cell, table }) => (
          <>
            Total Cost - {table.getColumn(cell.row.groupingColumnId ?? "").columnDef.header}:{" "}
            <MDBox sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}>
              {cell.getValue()?.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MDBox>
          </>
        ),
        Cell: ({ cell }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {cell.getValue()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </>
        ),
        Footer: () => (
          <Stack>
            Invoice Total:
            <MDBox color="warning.main">
              {totalCost.toLocaleString?.("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </MDBox>
          </Stack>
        ),
      },
    ],
    [loading]
  );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={4} px={2}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} px={2}>
        <MaterialReactTable
          columns={columns}
          data={rows}
          enableGrouping
          enableStickyHeader
          enableStickyFooter
          enableRowSelection={(row) => !row.original.senttoProlorus}
          enableSelectAll={false}
          enableColumnActions={false}
          enablePagination={false}
          initialState={{
            density: "compact",
            expanded: false,
            grouping: ["orchard", "date"],
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [{ id: "orchard", desc: false }],
            tableLayout: "fixed",
          }}
          muiToolbarAlertBannerChipProps={{ color: "primary" }}
          muiTableContainerProps={{ sx: { maxHeight: 700 } }}
          muiTableHeadCellProps={{
            // no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: () => ({
              backgroundColor: "lightblue",
            }),
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            hover: false,
            sx: () => ({
              backgroundColor: row.original.color,
              cursor: "pointer",
            }),
          })}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiSelectCheckboxProps={({ row }) => ({
            color: row.original.senttoProlorus === true ? "white" : "secondary",
          })}
          renderTopToolbarCustomActions={({ table }) => {
            const handleProlorusExport = () => {
              if (table.getSelectedRowModel().flatRows.length < 1) {
                alert("Select at least one row!");
              } else {
                handleProlorusAuthorization(
                  table.getSelectedRowModel(),
                  table.getSelectedRowModel().flatRows
                );
              }
            };

            const handleUnbillable = () => {
              if (table.getSelectedRowModel().flatRows.length < 1) {
                alert("Select at least one row!");
              } else {
                handleSetUnbillable(table.getSelectedRowModel().rows);
              }
            };

            return (
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <MDButton
                  color="primary"
                  // disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleProlorusExport}
                  variant="contained"
                >
                  {loading ? <CircularProgress color="secondary" /> : "Send to Prolorus"}
                </MDButton>
                <MDButton
                  color="warning"
                  // disabled={!table.getIsSomeRowsSelected()}
                  onClick={handleUnbillable}
                  variant="contained"
                >
                  {loading ? <CircularProgress color="primary" /> : "Set to Unbillable/Billable"}
                </MDButton>
              </div>
            );
          }}
        />
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
InvoiceSprayList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
InvoiceSprayList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  invoiceData: PropTypes.objectOf.isRequired,
  chemicals: PropTypes.objectOf.isRequired,
  senttoProlorus: PropTypes.objectOf.isRequired,
};

export default InvoiceSprayList;
